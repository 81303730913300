import React from 'react'

const HeaderGeneric = (props) => (
  <header id="header">
    <h1>Frederick Scottish Rite</h1>
    <p>
      Composed of the Lodge of Perfection, Chapter of Rose Croix,
      <br />
      and Council of Kadosh in Frederick, Maryland
    </p>
  </header>
)

export default HeaderGeneric
